document.querySelectorAll('a#expand').forEach(link => {
  link.addEventListener('click', (e) => {
     let result = e.target.parentNode.href;
    if (result != null) {
      result = result.substring(result.indexOf("#"));
      // result = "'"+result+"'";
      // console.log(result);
      toggle(result);
    } else if (e.target.href != null) {
     let result = e.target.href;
        result = result.substring(result.indexOf("#"));
             // result = "'"+result+"'";
             // console.log(result);
             toggle(result);

    }
  });
});

function toggle(result) {
  console.log(result);
  let toggled = document.querySelector(result);
  let collapse = document.getElementsByClassName('expanding-divs');
  // console.log(collapse);
  // console.log(toggled);
  for (var i = 0; i < collapse.length; i++) {
    // console.log(i);
    if (collapse[i] !== toggled) {
     if (collapse[i].classList.contains("alive")) {
      collapse[i].classList.add("hidden");
      collapse[i].classList.remove("alive");
      // console.log("Killing alive" + i)
    }
    }
    
  }
  if (toggled.classList.contains("hidden") && !toggled.classList.contains("alive")) {
    toggled.classList.remove("hidden");
    toggled.classList.add("alive");
    console.log("Remove Hidden");
    } else if (toggled.classList.contains("alive")) {
      toggled.classList.add("hidden");
      toggled.classList.remove("alive");
    console.log("ELSE");
    }
  
  }