document.querySelectorAll('a#toggle').forEach(link => {
    link.addEventListener('click', (e) => {
        // console.log(e.target);
        toggle(e)

  });
});

function toggle(e) {
   let result = e.target.className;
   console.log(result)
   let preview = '#preview_' + result.split('_')[1];
   console.log(preview)
   let full = '#full-content_' + result.split('_')[1];

   let pre = document.querySelector(preview);
   let fu = document.querySelector(full);
    console.log(fu)
    console.log(pre)
    
    pre.classList.toggle("hidden");
    fu.classList.toggle("hidden");

  }