const carousel = document.querySelector(".grid-carousel");
const outer = document.querySelector(".outer-carousel");
const leftButton = document.querySelector(".left");
const rightButton = document.querySelector(".right");
const slides = document.querySelectorAll(".grid-carousel__slide");

  const getOrder = elem => {
    const styles = getComputedStyle(elem);
    const orderValue = styles.order;
    const order = parseInt(orderValue);
    // console.log(orderValue)
    return order;
  }

  const moveRight = _ => {
    slides.forEach(function (slide) {
      order = getOrder(slide);

      if (order < slides.length) {
        slide.style.order = order += 1;
      } else {
        slide.style.order = 1;
      }
    });
  }

  const moveLeft = _ => {
    slides.forEach(function (slide) {
      order = getOrder(slide)
    
      if (order > 1) {
        slide.style.order = order -= 1;
      } else {
        slide.style.order = 12;
      }
    
    });
  }
rightButton.addEventListener('click', _ => {
  moveRight();
});

leftButton.addEventListener('click', _ => {
  moveLeft();
});
