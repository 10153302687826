document.querySelectorAll('div.history-carousel__slide').forEach(ele => {
  ele.addEventListener('click', (e) => {
    // console.log(e.target.tagName.toLowerCase())
     flip(e);
    
   
  });
  ele.addEventListener('mouseover', (e) => {
    // console.log(e.target.tagName.toLowerCase())
    flip(e);
  });
  ele.addEventListener('mouseout', (e) => {
    // console.log(e.target.tagName.toLowerCase())
    flip(e);
  });
});

function flip(e) {

  // e.classList.add("alive");
  if (e.target.tagName.toLowerCase() !== 'div') {
  let result = e.target.id;
  let p = '#p_' + result.split('_')[1];
  let h = '#h_' + result.split('_')[1];
  let image = '#image_' + result.split('_')[1];
  let container = '#hover-div_' + result.split('_')[1];
   
  let head = document.querySelector(h);
  let paragraph = document.querySelector(p);
  let toggle = document.querySelector(image);
  let div = document.querySelector(container);
  
  toggle.classList.toggle("filter");
  head.classList.toggle("hidden");
  paragraph.classList.toggle("hidden");
  div.classList.toggle("background");
    
  } else {

  let result = e.target.id;
  let p = '#p' + result.charAt(result.length - 1);
  let h = '#h' + result.charAt(result.length - 1);
  result = "#" + result;
  // console.log(result);
  // // console.log(p);
  // // console.log(h);
  let div = document.querySelector(result);
  let head = document.querySelector(h);
  let paragraph = document.querySelector(p);
  console.log();
  div.classList.toggle("filter");
  head.classList.toggle("hidden");
  paragraph.classList.toggle("hidden");
  // div.classList.toggle("filter");
}

}

document.querySelectorAll('.history-button').forEach(ele => {
  ele.addEventListener('click', (e) => {
    // console.log(e.target.tagName.toLowerCase())
    
  
    slide(e);
    
   
  });
  // ele.addEventListener('onmousemove', (e) => {
  //   // console.log(e.target.tagName.toLowerCase())
    
  
  //   auto_slide(e);
    
   
  // });

});

function slide(direction) {
  let result = direction.target.id;
  let slider = document.querySelector('.history-carousel');
  if (result == 'history-left') {
    document.getElementById('inner').scrollLeft -= 400;
  } else if (result == 'history-right') {
    document.getElementById('inner').scrollLeft += 400;
  }
}
// function auto_slide(direction) {
//   let result = direction.target.id;

//   if (result == 'history-left') {
//     document.getElementById('inner').scrollLeft -= 20;
//   } else if (result == 'history-right') {
//     document.getElementById('inner').scrollLeft += 20;
//   }


// }