// Get the modal
var modal = document.getElementById("cart-modal");
//
// // Get the button that opens the modal
// var btn = document.getElementById("cart-button");
// //
// Get the <span> element that closes the modal
var span = document.getElementsByClassName("cart-close")[0];

// // When the user clicks the button, open the modal
// btn.onclick = function() {
//     // modal.__vue__._props.open = true;
//     modal.style.display = "block";
// }
//
// When the user clicks on <span> (x), close the modal
span.onclick = function() {
    modal.style.display = "none";
}



