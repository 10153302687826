const parent = document.querySelector(".parent");
const child = document.querySelector(".child");
const toggle = document.querySelector(".toggle")
const toggleMobile = document.querySelector(".toggleMobile")
const hero = document.querySelector(".hero")
const menu = document.querySelector(".menu")
const header = document.querySelector(".header")

const allValues = document.querySelectorAll(".parent")
const allChildren = document.querySelectorAll(".child");

for  (let i = 0; i < allValues.length; i++) {
    // console.log(allValues[i])
    allValues[i].addEventListener('click', _ => {
        expandChildrenSpecific(allValues[i]);
});
}

const expandChildrenSpecific = (value) => {
    let search = value.getAttribute("href");
    value.classList.toggle("text-font-blue");
    value.classList.toggle("items-center");
    value.classList.toggle("items-baseline");

    let valuePlus = document.getElementById("plus" + search)
    let valueMinus = document.getElementById("minus" + search)
    let valueChild = document.getElementById(search)
    valueChild.classList.toggle("hidden")
    valuePlus.classList.toggle("hidden")
    valueMinus.classList.toggle("hidden")


}



//
// const expandChildren = _ => {
//     child.classList.toggle("hidden");
//     parent.classList.toggle("text-font-blue");
// }
const expandMenu = _ => {
    // toggle.classList.toggle("hidden");
    hero.classList.toggle("hidden");
    menu.classList.toggle("hidden");
    header.classList.toggle("header-color"); 
}


// parent.addEventListener('click', _ => {
//     expandChildren();
// });

toggle.addEventListener('click', _ => {
    expandMenu();
});

toggleMobile.addEventListener('click', _ => {
    expandMenu();
});